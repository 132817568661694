import {createUseStyles} from 'react-jss'
import color from 'color';

import {
  BREAKPOINTS,
  COLORS
} from '~styles/vars.styles'

import {
  input,
  link,
  tooltip,
  uppercaseLabel
} from '~styles/mixins.styles'

import {
  TRANSITION as TRANSITION_COMMON
} from '@theme/vars';

export default createUseStyles({
  planCalculator: {
    color: COLORS.BLUE_MUTED_01
  },

  boxTitle: {
    ...uppercaseLabel,
    textAlign: 'center',
    marginBottom: 20
  },

  box: {
    boxShadow: `0px 2px 6px ${color(COLORS.BLUE_02).lighten(0.6).string()}`,
    marginBottom: 60
  },

  boxHeader: {
    alignItems: 'center',
    background: COLORS.LIGHT_02,
    color: COLORS.BLUE_MUTED_02,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: 20,
    width: '100%'
  },

  /**
   * Slider
   */

  qualitySliderAndTally: {
    display: 'flex',
    flexDirection: 'row'
  },

  quantitySlider: {
    flex: 1,
    // Uncomment when we want to add the tall
    // padding: [60, 30, 60, 60]
    padding: 60
  },

  tally: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: [60, 30, 60, 30],
    borderLeft: `1px solid ${COLORS.LIGHT_02}`
  },

  tallyTitle: {
    color: COLORS.BLUE_MUTED_02,
    fontSize: 14,
    fontWeight: 600,
    letterSpacing:'-0.03777em',
    marginBottom: 15,
    textAlign: 'center'
  },

  tallySubtitle: {
    color: COLORS.BLUE_MUTED_02,
    fontSize: 14,
    fontWeight: 500
  },

  tallyValue: {
    color: COLORS.BLUE_MUTED_01,
    fontSize: 60,
    letterSpacing: '-0.045em',
    marginBottom: 20
  },

  quantityTitle: {
    fontSize: 28,
    fontWeight: 600,
    letterSpacing:'-0.03777em',
    marginBottom: 20,
    textAlign: 'center'
  },

  quantitySliderInput: {
    flex: 1
  },

  boxOverview: {
    alignItems: 'stretch',
    borderTop: `1px solid ${COLORS.LIGHT_02}`,
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%'
  },

  overviewItem: {
    margin: 20,
    width: '100%',
    [`@media (min-width: ${BREAKPOINTS.PLAN_CALCULATOR_BIG}px)`]: {
      flex: 1  
    }
  },

  overviewItemLabel: {
    ...uppercaseLabel
  },

  overviewItemValue: {
    fontSize: 24,
    fontWeight: 600,
    letterSpacing:'-0.03777em'
  },

  overviewItemPlaceholder: {
    borderRadius: 6,
    boxShadow: `inset 0 0 0 3px ${COLORS.LIGHT_02}`,
    cursor: 'pointer',
    height: 60,
    padding: [15, 20],
    '&:hover': {
      background: COLORS.LIGHT_02,
      transition: `background ${TRANSITION_COMMON.DURATION_SM}ms ease-in-out`
    }
  },

  overviewItemInput: {
    ...input,
    height: 60,
    marginBottom: 0,
    width: '100%'
  },

  overviewItemDisplayOnly: {
    fontSize: 24,
    borderRadius: 6,
    height: 60,
    padding: [15, 20]
  },

  tooltip: {
    ...tooltip
  },

  /**
   * Others
   */

  link: {
    ...link({
      color: COLORS.BLUE_02,
      fontWeight: 600
    })
  },

  alternateLink: {
    color: COLORS.BLUE_02,
    textDecoration: 'underline',
    cursor: 'pointer'
  },

  notificationIcon: {
    marginRight: 15
  },

  label: {
    display: 'block',
    marginBottom: 20
  },

  input: {
    ...input,
    textAlign: 'center',
    width: 100
  },

  rangeTicks: {
    position: 'relative',
    zIndex: -1 // Position beneath range thumb
  },
  rangeTickMark: {
    width: 1,
    height: 10,
    background: COLORS.LIGHT_03,
    position: 'absolute',
    top: -10,
    left: 0
  },
  rangeTickMarkValue: {
    color: COLORS.BLUE_02,
    position: 'absolute',
    fontWeight: 600,
    fontSize: 10,
    top: 10,
    left: 0,
    transform: 'translateX(-50%)'
  },

  planLoginOrCheckout: {
    marginBottom: 60
  }
})