import React, { useEffect, useState } from "react"
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import {
  ALERT_TYPE_SUCCESS,
  PLAN_EXPLAINER_VIDEO_URL,
  PLAN_TYPE_RECIPIENT_PAYS,
  PLAN_TYPE_SENDER_PAYS,
  PLAN_TYPE_UNLIMITED_INVOICE_MONTHLY,
  PLAN_TYPE_UNLIMITED_FOR_REAL,
  STRIPE_PLAN_RECIPIENT_PAYS,
  STRIPE_PLAN_SENDER_PAYS,
  STRIPE_PLAN_OTHER
} from '~common/constants'

import Alert from '~components/alert/alert.component'
import PlanCards from '~components/plan-cards/plan-cards.component'
import PlanCalculator from '~components/plan-calculator/plan-calculator.component'
import PlanBullets from '~components/plan-bullets/plan-bullets.component'

import Layout from "~components/layout/layout.component"
import SEO from "~components/seo/seo.component"

import useQuerystring from '~common/use-querystring.hook';
import useStyles from '~components/pick-a-plan/pick-a-plan.styles';

const PickAPlanPage = props => {
  const classes = useStyles(props);

  /**
   * Store the selected plan, defaulting to sender pays
   */
  const [selectedPlan, setSelectedPlan] = useState(STRIPE_PLAN_SENDER_PAYS);

  /**
   * Retrieve querystring parameters for the organization name and stripe plan type
   */
  const { n, type } = useQuerystring();

  /**
   * Show an alert if a plan is already selected
   */
  const [showAlert, setShowAlert] = useState(null);

  const [alertSubtitle, setAlertSubtitle] = useState('Your account plan is locked in.');

  /**
   * Prevent the selection of plans if one is selected
   */
  const [disableSelectPlan, setDisableSelectPlan] = useState(false);

  /**
   * If a type is returned from the backend, disable plan changes.
   */
  useEffect(() => {
    if (parseInt(type) === PLAN_TYPE_SENDER_PAYS) {
      setSelectedPlan(STRIPE_PLAN_SENDER_PAYS);
      setShowAlert(true);
      setDisableSelectPlan(true);
    } else if (parseInt(type) === PLAN_TYPE_RECIPIENT_PAYS) {
      setSelectedPlan(STRIPE_PLAN_RECIPIENT_PAYS);
      setShowAlert(true);
      setDisableSelectPlan(true);
    } else if (parseInt(type) === PLAN_TYPE_UNLIMITED_INVOICE_MONTHLY) {
      setSelectedPlan(STRIPE_PLAN_OTHER)
      setShowAlert(true);
      setDisableSelectPlan(true);
      setAlertSubtitle('Your account plan is locked in and you will be invoiced.')
    } else if (parseInt(type) === PLAN_TYPE_UNLIMITED_FOR_REAL) {
      setSelectedPlan(STRIPE_PLAN_OTHER)
      setShowAlert(true);
      setDisableSelectPlan(true);
      setAlertSubtitle('Your account is on the unlimited + free plan.')
    }
  }, [type])


  return (
    <>
      { showAlert ?
      <Alert
        alertType={ALERT_TYPE_SUCCESS}
        alertTitle={n}
        alertSubtitle={alertSubtitle}
        onClose={() => setShowAlert(false)}
      /> : null }
      <Layout includeAlert={showAlert}>
        <SEO title="Pick A Plan" />
        
        <PlanCards
          disableSelectPlan={disableSelectPlan}
          selectedPlan={selectedPlan}
          onSelectPlan={value => setSelectedPlan(value)}
        />
        
        {/* If the user has a selected plan that is not a standard selection, hide the calculator */}
        { selectedPlan === STRIPE_PLAN_OTHER ? 
        null :
        <PlanCalculator
          disableSelectPlan={disableSelectPlan}
          selectedPlan={selectedPlan}
          onSelectPlan={value => setSelectedPlan(value)}
        />}

        <OutboundLink
          className={classes.linkWatchVideo}
          href={PLAN_EXPLAINER_VIDEO_URL}
          target="_new"
        >
          Watch video to learn more
        </OutboundLink>

        { selectedPlan === STRIPE_PLAN_OTHER ?
        null :
        <PlanBullets
          className={classes.planBullets}
          selectedPlan={selectedPlan}
        />}
        
        <Link to="/">Go back to the homepage</Link>
      </Layout>
    </>
  )
}

export default PickAPlanPage
