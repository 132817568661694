import React, { useEffect, useState }  from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import Button from '~components/button/button.component';

import { isTagNode, getUrl, legacyHost, redirectUrl, cleanRequestResponse } from '~common/legacy-helpers';

import useStyles from './admin-login-form.styles';

const AdminLoginForm = props => {

  const classes = useStyles(props);

  // Page response of fetched login.php (for the sake of grabbing hidden field values)
  const [pageMarkup, setPageMarkup] = useState(null);

  // Grab the admin log in page form when a hash is set
  useEffect(() => {
    async function fetchPage() {
      try {
        const data = await getUrl(`https://www.rainedout.net/admin/login.php?a=${props.organizationHash}`)
        setPageMarkup(data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchPage();
  }, [props, props.organizationHash]);

  const customRequestResponse = (node, index) => {
    if (isTagNode(node) && node.name === 'form' && node.attribs.action === 'login.php') {
      node.attribs.action = `${legacyHost}admin/${node.attribs.action}`;
      return convertNodeToElement(node, index, customRequestResponse);
    } else if (isTagNode(node) && node.name === 'a' && node.attribs.href === 'forgot_username.php') {
      node.attribs.href = `${legacyHost}admin/${node.attribs.href}?a=${props.organizationHash}`;
      return convertNodeToElement(node, index, customRequestResponse);
    } else if (isTagNode(node) && node.name === 'a' && node.attribs.href === 'forgot_password.php') {
      node.attribs.href = `${legacyHost}admin/${node.attribs.href}?a=${props.organizationHash}`;
      return convertNodeToElement(node, index, customRequestResponse);
    } else if (isTagNode(node) && node.name === 'input' && node.attribs.name === 'tpl') {
      return convertNodeToElement (node, index, customRequestResponse);
    } else if (isTagNode(node) && node.name === 'a' && node.attribs.href === 'https://www.mcafeesecure.com/RatingVerify?ref=www.rainedout.net') {
      // Hide broken McAfee badge
      return null;
    } else if (isTagNode(node) && node.name === 'div' && node.attribs.class === 'required_field') {
      // Hide '* required field'
      return null;
    } else if (isTagNode(node) && node.name === 'input' && node.attribs.type === 'submit') {
      return (
        <>
          <Button inputSubmit={true} text="Submit" size="small" key={index} />
          {/* Glue redirect field that's processed by the receiving form action php file where a redirect is performed. */}
          {props.redirectUrl ? <input name="redirect_url" defaultValue={redirectUrl(props.redirectUrl)} onChange={() => {}} type="hidden" key={index} /> : null}
        </>
      )
    }

    if (isTagNode(node)) {
      const cleanNode = cleanRequestResponse(node);
      if (cleanNode) {
        return convertNodeToElement(cleanNode, index, customRequestResponse);
      } else if (cleanNode === null) {
        return null;
      }
    }
  }

  return (
    props.organizationHash ? 
    <div className={classes.adminLoginForm}>
      {pageMarkup ? ReactHtmlParser(pageMarkup, { transform: customRequestResponse }) : null}
    </div> : null
  )
}

AdminLoginForm.propTypes = {
  /**
   * Hash identifier for organization
   */
  organizationHash: PropTypes.string,

  /**
   * Submitting this form will place the user on the legacy rainedout.net page.
   * We can optionally pass a redirect url where they will land on form submission.
   */
  redirectUrl: PropTypes.string
}

AdminLoginForm.defaultProps = {
  organizationHash: null,
  redirectUrl: null
}

export default AdminLoginForm
