import {createUseStyles} from 'react-jss'

import color from 'color';

import {
  BREAKPOINTS,
  COLORS
} from '~styles/vars.styles'

import {
  uppercaseLabel
} from '~styles/mixins.styles'

import {
  TRANSITION as TRANSITION_COMMON
} from '@theme/vars';


export default createUseStyles({
  planCard: {
    border: `3px solid ${color(COLORS.LIGHT_02).darken(0.1).string()}`,
    borderRadius: 6,
    background: COLORS.LIGHT_02,
    padding: 40,
    cursor: 'pointer',
    '&:hover': {
      boxShadow: `0 15px 35px ${color(COLORS.BLUE_MUTED_03).alpha(0.25).string()}`,
      transition: `all ${TRANSITION_COMMON.DURATION_SM}ms ease-in-out`
    },
    '&:hover $planName': {
      color: COLORS.BLUE_MUTED_01
    }
  },

  planCardSelected: {
    color: COLORS.BLUE_MUTED_01,
    background: COLORS.LIGHT_01,
    borderColor: COLORS.GREEN_01,
    cursor: 'default',
    boxShadow: `0 15px 35px ${color(COLORS.BLUE_MUTED_03).alpha(0.25).string()}`,
    '& $planName': {
      color: COLORS.BLUE_MUTED_01
    }
  },

  planCardDisabled: {
    cursor: 'not-allowed',
    outline: 'none',
    '& $planName': {
      color: COLORS.BLUE_MUTED_02
    },
    '& $planDescription': {
      color: color(COLORS.BLUE_MUTED_02).alpha(0.5).string()
    },
    '&:hover $planName': {
      color: COLORS.BLUE_MUTED_02
    },
    '&:hover': {
      boxShadow: `none`,
      transition: `none`
    },
  },

  planPrefix: {
    ...uppercaseLabel,
    paddingBottom: 15,
    marginBottom: 15,
    borderBottom: `1px solid ${color(COLORS.BLUE_MUTED_03).alpha(0.1).string()}`
  },

  planName: {
    color: color(COLORS.BLUE_MUTED_01).alpha(0.85).string(),
    fontSize: 30,
    letterSpacing: '-0.045em',
    lineHeight: '1em',
    fontWeight: 600,
    marginBottom: 30,
    [`@media (min-width: ${BREAKPOINTS.MENU_BIG}px)`]: {
      fontSize: 38
    }
  },

  planDescription: {
    maxWidth: 320,
    marginBottom: 30,
    lineHeight: 1.5,
    letterSpacing:'-0.03777em',
    fontWeight: 400,
    fontSize: 16,
    color: COLORS.BLUE_MUTED_02,

    [`@media (min-width: ${BREAKPOINTS.MENU_BIG}px)`]: {
      fontSize: 18,
      marginBottom: 40
    }
  }
})