import React, { useState } from "react"
import classNames from 'classnames';

import {
  KEY_CODES
} from '~constants';

import Icon from '~components/icon/icon.component'

import useStyles from './plan-bullet.styles';

const PlanBullet = props => {
  const classes = useStyles(props);
  
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      className={classNames(
        [classes.planBullet],
        {
          [classes.planBulletExpanded]: expanded
        }
      )}
    >
      <div 
        className={classNames(
          [classes.title], {
            [classes.titleExpanded]: expanded
          }
        )}
        onClick={() => setExpanded(!expanded)}
        onKeyDown={event => {
          if (event.keyCode === KEY_CODES.ENTER) {
            setExpanded(!expanded);
          }}
        }
        role="button"
        tabIndex={0}
        aria-expanded={expanded}
      >
        <div className={classes.titleExpandButton} data-dismiss="modal" aria-label="Toggle Expand">
          <span aria-hidden="true">
          <Icon name={expanded ? 'subtract' : 'add' } size={14} />
          </span>
        </div>
        {props.title}
      </div>
      {expanded ?
      <div className={classes.body}>
        {props.children.map((item, idx) => (
          <div key={idx} className={classes.item}>
            <div className={classes.itemTitle}>{item.title}</div>
            <div>{item.body()}</div>
          </div>
        ))}
      </div> :
      null}
    </div>
  );
}

export default PlanBullet
