import React from 'react'
import PropTypes from 'prop-types';

import {
  ANNUAL_COST_USD,
  CURRENCY,
  LOCALE,
  STRIPE_PLAN_RECIPIENT_PAYS,
  STRIPE_PLAN_SENDER_PAYS,
  UNIT_COST_USD
} from '~common/constants'

import PlanCard from '~components/plan-card/plan-card.component';

import useStyles from './plan-cards.styles';

const PLAN_DESCRIPTION_SENDER_PAYS = <>This plan is for people looking to purchase a bundle of messages and use them as needed.  Messages do not expire.  The cost is {new Intl.NumberFormat(LOCALE, { style: 'currency', currency: CURRENCY }).format(UNIT_COST_USD)} per message to each phone you send a message to. There is no cost to the recipient in this plan.  E-mail is <b>FREE</b> to send and receive.</>;
const PLAN_DESCRIPTION_RECIPIENT_PAYS = <>In this plan, the Recipient chooses unlimited messaging via text or email.  Receiving unlimited texts from unlimited RainedOut accounts costs {new Intl.NumberFormat(LOCALE, { style: 'currency', currency: CURRENCY }).format(ANNUAL_COST_USD)} per year.  There is no cost to the sender in this plan.  Email is <b>FREE</b> to send and receive.</>;

const PlanCards = props => {
  const classes = useStyles(props);

  return (
    <div className={classes.planCards}>
      <PlanCard
        className={classes.planCard}
        disableSelectPlan={props.disableSelectPlan}
        planName={<>Sender<br/>Pays</>}
        planDescription={PLAN_DESCRIPTION_SENDER_PAYS}
        selectedPlan={props.selectedPlan}
        planStripeId={STRIPE_PLAN_SENDER_PAYS}
        onClick={props.onSelectPlan}
      />
      <PlanCard
        className={classes.planCard}
        disableSelectPlan={props.disableSelectPlan}
        planName={<>Recipient's<br/>Choice</>}
        planDescription={PLAN_DESCRIPTION_RECIPIENT_PAYS}
        selectedPlan={props.selectedPlan}
        planStripeId={STRIPE_PLAN_RECIPIENT_PAYS}
        onClick={props.onSelectPlan}
      />
    </div>
  )
}

PlanCards.propTypes = {
  disableSelectPlan: PropTypes.bool,
  selectedPlan: PropTypes.oneOf([STRIPE_PLAN_RECIPIENT_PAYS, STRIPE_PLAN_SENDER_PAYS]),
  onSelectPlan: PropTypes.func,
}

PlanCards.defaultProps = {
  disableSelectPlan: false,
  selectedPlan: null,
  onSelectPlan: () => {}
}

export default PlanCards
