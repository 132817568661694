import React, { useEffect } from "react"
import PropTypes from 'prop-types';

import {
  ANALYTICS,
  STRIPE_MESSAGE_QTY_MIN,
  STRIPE_PLAN_RECIPIENT_PAYS,
  STRIPE_PLAN_SENDER_PAYS
} from '~common/constants'

import { trackCustomEvent } from '~common/helpers'

import Modal from '~components/modal/modal.component'
import PaymentForm from '~components/payment-form/payment-form.component'
import { Elements } from '~components/stripe-element/stripe-element.component'

const PaymentModal = props => {
  useEffect(() => {
    if (props.isShowing) {
      trackCustomEvent({
        category: ANALYTICS.CATEGORY.PAYMENT_FORM,
        action: ANALYTICS.ACTION.PAYMENT_FORM_CHECKOUT_INITIATED,
        label: props.organizationId,
        value: props.selectedPlan === STRIPE_PLAN_SENDER_PAYS ? props.estimatedMessages : 0
      })
    }
  }, [props.estimatedMessages, props.isShowing, props.organizationId, props.selectedPlan])

  return (
      props.organizationId ?
      <Modal
        title={props.selectedPlan === STRIPE_PLAN_SENDER_PAYS ? "Purchase Messages" : "Almost Done!"}
        isShowing={props.isShowing}
        hide={props.hide}
      >
        <Elements>
          <PaymentForm
            organizationId={props.organizationId}
            organizationHash={props.organizationHash}
            estimatedMessages={Math.max(STRIPE_MESSAGE_QTY_MIN, props.estimatedMessages)}
            selectedPlan={props.selectedPlan}
            onSuccess={() => {}}
            onError={() => {}}
          />
        </Elements>
      </Modal> : null
  )
}

PaymentModal.propTypes = {
  /**
   * Provide an initial quantity of messages to purchase
   */
  estimatedMessages: PropTypes.number,

  /**
   * Identifier for organization
   */
  organizationId: PropTypes.string,

  /**
   * Stripe plan that will receive the purchase order
   */
  selectedPlan: PropTypes.oneOf([STRIPE_PLAN_SENDER_PAYS, STRIPE_PLAN_RECIPIENT_PAYS]),
}

PaymentModal.defaultProps = {
  estimatedMessages: 100,
  organizationId: null
}

export default PaymentModal
