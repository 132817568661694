/**
 * Use this hook as a means to bind events directly to 'window'.
 * Cleanup occurs when mounting component is unmounted.
 */

import { useEffect, useRef, useState } from 'react';

import { isObjectEmpty } from '~common/helpers';

import qs from 'qs'

export default (emptyCallback = null) => {

  // Store references which are populated when the component is mounted.  SSR does not have window until after the component has mounted.
  let url = useRef();
  let querystring = useRef();

  const [params, setParams] = useState(null);

  // Set initial values based on querystring parameters if they exist
  useEffect(() => {
    // Grab the original link href, parse the querystring.
    url.current = new URL(window.location.href).search;
    querystring.current = qs.parse(url.current, { ignoreQueryPrefix: true });

    // If there's no querystring, fire empty callback.
    if (emptyCallback && isObjectEmpty(querystring.current)) {
      emptyCallback();
    }

    if (querystring && querystring.current) {
      setParams(querystring.current);
    }
  }, [querystring, url, emptyCallback]);

  return { ...params };
};
