import {createUseStyles} from 'react-jss'

export default createUseStyles({
  planCards: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: [0, -20, 40, -20]
  },
  planCard: {
    margin: 20,
    flex: 1,
    flexBasis: 250
  }
})