import {createUseStyles} from 'react-jss'

import color from 'color';

import {
  BREAKPOINTS,
  COLORS
} from '~styles/vars.styles'

import {
  uppercaseLabel
} from '~styles/mixins.styles'

import {
  TRANSITION as TRANSITION_COMMON
} from '@theme/vars';


export default createUseStyles({
  alert: {
    background: 'var(--alert-background)',
    display: 'flex',
    flexDirection: 'row',
    padding: 15,
    '&:hover': {
      // boxShadow: `0 15px 35px ${color(COLORS.BLUE_MUTED_03).alpha(0.25).string()}`,
      transition: `all ${TRANSITION_COMMON.DURATION_SM}ms ease-in-out`
    }
  },
  alertTypeCritical: {},
  alertTypeInfo: {
    '--alert-background': `linear-gradient(45deg, ${color(COLORS.PURPLE_01).rotate(10).string()}, ${COLORS.PURPLE_01})`,
    '--alert-border': color(COLORS.LIGHT_02).alpha(0.15).string(),
    '--alert-close': COLORS.ACCENT,
    '--alert-title': COLORS.LIGHT_01,
    '--alert-subtitle':  color(COLORS.LIGHT_02).alpha(0.8).string()
  },
  alertTypeSuccess: {
    '--alert-background': `linear-gradient(45deg, ${COLORS.GREEN_01} 75%, ${COLORS.GREEN_02})`,
    '--alert-border': color(COLORS.LIGHT_02).alpha(0.25).string(),
    '--alert-close': COLORS.LIGHT_02,
    '--alert-title': COLORS.LIGHT_01,
    '--alert-subtitle':  color(COLORS.LIGHT_02).alpha(0.8).string()
  },
  alertTypeWarning: {
    '--alert-background': `linear-gradient(45deg, ${COLORS.ORANGE_01} 75%, ${COLORS.ACCENT})`,
    '--alert-border': color(COLORS.DARK_02).alpha(0.15).string(),
    '--alert-close': COLORS.DARK_02,
    '--alert-title': COLORS.DARK_01,
    '--alert-subtitle':  color(COLORS.DARK_02).alpha(0.8).string()
  },

  body: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  close: {
    color: 'var(--alert-close)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 'none',
    cursor: 'pointer'
  },

  planPrefix: {
    ...uppercaseLabel,
    // borderBottom: `1px solid ${color(COLORS.BLUE_MUTED_03).alpha(0.1).string()}`
  },

  alertTitle: {
    fontSize: 14,
    color: 'var(--alert-title)',
    letterSpacing: '-0.045em',
    lineHeight: '1em',
    fontWeight: 600,

    '& + $alertSubtitle': {
      margin: [0, 15],
      padding: [0, 15],
      borderLeft: [1, 'solid', 'var(--alert-border)']
    },

    [`@media (min-width: ${BREAKPOINTS.MENU_BIG}px)`]: {
      fontSize: 18
    }
  },

  alertSubtitle: {
    color: 'var(--alert-subtitle)',
    lineHeight: 1.5,
    letterSpacing:'-0.03777em',
    fontWeight: 400,
    fontSize: 12,

    [`@media (min-width: ${BREAKPOINTS.MENU_BIG}px)`]: {
      fontSize: 16
    }
  }
})