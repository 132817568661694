import {createUseStyles} from 'react-jss'

import {
  input,
  inputLegacy,
  label,
  requiredLegacy
} from '~styles/mixins.styles'

export default createUseStyles({
  /**
   * jss-plugin-global uses `@global` decorator allowing us
   * to style against the php form's search results.  These
   * class names are defined by the old php form.
   */
  '@global': {
    '.fieldBox': {
      ...requiredLegacy,
      border: 'none'
    },

    '.fieldBox input:not([type=submit])': {
      ...input,
      ...inputLegacy(false)
    },
    
    '.labelBox': {
      ...label
    },

    '[type=checkbox] + .labelBox': {
      display: 'initial',
      marginLeft: 5
    },

    'form + .fieldBox': {
      margin: 0,
      '& label': {
        display: 'none'
      }
    },

    'form + .fieldBox + .fieldBox': {
      margin: 0,
      '& label': {
        display: 'none'
      }
    }
  },

  adminLoginForm: {}
})