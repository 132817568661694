import React from 'react'
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  ALERT_TYPE_CRITICAL,
  ALERT_TYPE_INFO,
  ALERT_TYPE_SUCCESS,
  ALERT_TYPE_WARNING,
  KEY_CODES
} from '~common/constants'

import Icon from '~components/icon/icon.component';

import useStyles from './alert.styles';

const Alert = props => {
  const classes = useStyles(props);

  return (
    <div className={
      classNames([classes.alert], {
        [classes.alertTypeCritical]: props.alertType === ALERT_TYPE_CRITICAL,
        [classes.alertTypeInfo]: props.alertType === ALERT_TYPE_INFO,
        [classes.alertTypeSuccess]: props.alertType === ALERT_TYPE_SUCCESS,
        [classes.alertTypeWarning]: props.alertType === ALERT_TYPE_WARNING,
        [props.className]: !!props.className
      })}
    >
      <div className={classes.body}>
        { props.alertTitle ? <div className={classes.alertTitle}>{props.alertTitle}</div> : null }
        { props.alertSubtitle ? <div className={classes.alertSubtitle}>{props.alertSubtitle}</div> : null}
      </div>
      <div
        role="button"
        className={classes.close}
        onClick={event => props.onClose(event)}
        tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
        onKeyDown={event => {
            if (event.keyCode === KEY_CODES.ENTER) {
              props.onClose(event);
            }
          }
        }
      >
        <Icon name="close" size={12} />
      </div>
    </div>
  )
}

Alert.propTypes = {
  alertSubtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  alertTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  alertType: PropTypes.oneOf([
    ALERT_TYPE_CRITICAL,
    ALERT_TYPE_INFO,
    ALERT_TYPE_SUCCESS,
    ALERT_TYPE_WARNING
  ]),
  className: PropTypes.string,
  onClose: PropTypes.func
}

Alert.defaultProps = {
  alertSubtitle: '',
  alertTitle: '',
  alertType: ALERT_TYPE_INFO,
  className: '',
  onClose: () => {}
}

export default Alert
