import React from 'react'
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '~components/button/button.component';

import useStyles from './plan-login-or-checkout.styles';

const PlanLoginOrCheckout = props => {
  const classes = useStyles(props);

  return (
    <div className={
      classNames([classes.planLoginOrCheckout], {
        [props.className]: !!props.className
      })}
    >
      {props.showLogin ? 
      <div className={classes.buttonGroup}>
        <Button
          className={classes.buttonSmall}
          onClick={event => props.onLogin(event)}
          shadow={true}
          text={props.loginText}
          size="small"
          theme="blue"
          dataTip="If you already have an account, login to pick a plan"
          dataTipId="login-button"
        />
        <div className={classes.buttonBetwixt}>OR</div>
        <Button
          className={classes.buttonSmall}
          onClick={event => props.onCreateOrganization(event)}
          shadow={true}
          text="Create Account"
          size="small"
          theme="blue"
          dataTip="If you're new to RainedOut, click here to sign up then pick a plan"
          dataTipId="create-account-button"
        />
      </div> :
      <Button
      className={classes.button}
      onClick={event => props.onCheckout(event)}
      iconRight="arrow-right"
      shadow={true}
      text={props.checkoutText}
      theme="blue"
    /> }
    </div>
  )
}

PlanLoginOrCheckout.propTypes = {
  className: PropTypes.string,
  checkoutText: PropTypes.string,
  loginText: PropTypes.string,
  showLogin: PropTypes.bool.isRequired,
  onCheckout: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  onCreateOrganization: PropTypes.func.isRequired
}

PlanLoginOrCheckout.defaultProps = {
  className: '',
  checkoutText: 'Checkout',
  loginText: 'Log In'
}

export default PlanLoginOrCheckout
