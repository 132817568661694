import React from 'react'
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  KEY_CODES,
  STRIPE_PLAN_SENDER_PAYS,
  STRIPE_PLAN_RECIPIENT_PAYS
} from '~common/constants'

import useStyles from './plan-card.styles';

const PlanCard = props => {
  const classes = useStyles(props);
  const isDisabled = props.selectedPlan !== props.planStripeId && props.disableSelectPlan;

  return (
    <div className={
      classNames([classes.planCard], {
        [classes.planCardSelected]: props.selectedPlan === props.planStripeId,
        [classes.planCardDisabled]: isDisabled,
        [props.className]: !!props.className
      })}
      onClick={event => {
        if (!isDisabled) {
          props.onClick(props.planStripeId)
        }
      }}
      onKeyDown={event => {
        if (!isDisabled && event.keyCode === KEY_CODES.ENTER) {
          props.onClick(props.planStripeId)
        }}
      }
      role="button"
      tabIndex={isDisabled ? -1 : 0}
    >
      <div className={classes.planPrefix}>{props.selectedPlan === props.planStripeId ? 'Selected Plan' : 'Select Plan'}</div>
      <div className={classes.planName}>{props.planName}</div>
      <div className={classes.planDescription}>{props.planDescription}</div>
    </div>
  )
}

PlanCard.propTypes = {
  className: PropTypes.string,
  disableSelectPlan: PropTypes.bool,
  planDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  planName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  selectedPlan: PropTypes.oneOf([STRIPE_PLAN_RECIPIENT_PAYS, STRIPE_PLAN_SENDER_PAYS]),
  planStripeId: PropTypes.oneOf([STRIPE_PLAN_RECIPIENT_PAYS, STRIPE_PLAN_SENDER_PAYS]),
  unitCost: PropTypes.number
}

PlanCard.defaultProps = {
  className: '',
  disableSelectPlan: false,
  planName: 'Plan Name',
  planDescription: 'This is a basic plan description describing for whom the plan is best suited.',
  planSelected: null,
  planStripeId: null,
  unitCost: 1.00
}

export default PlanCard
