import {createUseStyles} from 'react-jss'
import color from 'color';

import {
  uppercaseLabel
} from '~styles/mixins.styles'

import {
  COLORS
} from '~styles/vars.styles'

import {
  TRANSITION as TRANSITION_COMMON
} from '@theme/vars';

export default createUseStyles({
  planBullet: {
    width: '100%',
    border: `1px solid ${COLORS.LIGHT_02}`,
    cursor: 'pointer'
  },
  title: {
    fontSize: 18,
    color: COLORS.BLUE_MUTED_01,
    padding: [15, 20],
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      background: COLORS.LIGHT_02,
      boxShadow: `0 15px 35px ${color(COLORS.BLUE_MUTED_03).alpha(0.25).string()}`,
      transition: `background ${TRANSITION_COMMON.DURATION_SM}ms ease-in-out, box-shadow ${TRANSITION_COMMON.DURATION_SM}ms ease-in-out`
    }
  },
  titleExpanded: {
    background: COLORS.LIGHT_02,
    borderBottom: `1px solid ${COLORS.LIGHT_03}`
  },
  titleExpandButton: {
    marginRight: 20,
    alignItems: 'center',
    background: color(COLORS.BLUE_02).alpha(0.15).string(),
    borderRadius: '50%',
    color: COLORS.BLUE_02,
    cursor: 'pointer',
    display: 'flex',
    flex: 'none',
    height: 30,
    justifyContent: 'center',
    width: 30
  },
  body: {
    fontSize: 16,
    padding: [20, 40],
    background: COLORS.LIGHT_02
  },
  item: {
    '& ul': {
      marginBottom: 0
    }
  },
  itemTitle: {
    ...uppercaseLabel,
    marginBottom: 10
  }
})