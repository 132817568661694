import React, { useEffect, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { Range, getTrackBackground } from 'react-range';
import ReactTooltip from 'react-tooltip'
import qs from 'qs'

import {
  ANALYTICS,
  ANNUAL_COST_USD,
  CURRENCY,
  KEY_CODES,
  LOCALE,
  STRIPE_MESSAGE_QTY_MIN,
  STRIPE_PURCHASE_MIN,
  STRIPE_PLAN_RECIPIENT_PAYS,
  STRIPE_PLAN_SENDER_PAYS,
  UNIT_COST_USD
} from '~common/constants'

import {
  isObjectEmpty,
  numberWithCommas,
  trackCustomEvent
} from '~common/helpers'

import {
  rangeThumb,
  rangeThumbInner,
  rangeTrack,
  rangeTrackInner
} from '~styles/mixins.styles';

import {
  COLORS
} from '~styles/vars.styles';

import AdminLoginModal from '~components/admin-login-modal/admin-login-modal.component';
import PaymentModal from '~components/payment-modal/payment-modal.component';
import PlanLoginOrCheckout from '~components/plan-login-or-checkout/plan-login-or-checkout.component';

import Notification from '~images/artwork/notification.svg';

import useStyles from './plan-calculator.styles';

/**
 * Bounds for SMS recipient quantity
 */
const SMS_RECIPIENT_TICK_COUNT = 40;
const SMS_RECIPIENT_MINIMUM = 1;
const SMS_RECIPIENT_MAXIMUM = 1000;
const SMS_RECIPIENT_STEP = Math.round((SMS_RECIPIENT_MAXIMUM - SMS_RECIPIENT_MINIMUM) / SMS_RECIPIENT_TICK_COUNT);
const SMS_RECIPIENT_DEFAULT = SMS_RECIPIENT_STEP * 2;
const SMS_RECIPIENT_TICKS = [
  SMS_RECIPIENT_MINIMUM,
  Math.round((SMS_RECIPIENT_MAXIMUM - SMS_RECIPIENT_MINIMUM) / 4),
  Math.round((SMS_RECIPIENT_MAXIMUM - SMS_RECIPIENT_MINIMUM) / 2),
  Math.round((SMS_RECIPIENT_MAXIMUM - SMS_RECIPIENT_MINIMUM) / 1.332),
  SMS_RECIPIENT_MAXIMUM
]

/**
 * Default number of SMS sent to each respective recipient
 */
const SMS_PER_RECIPIENT_DEFAULT = 10;

const PlanCalculator = props => {
  const classes = useStyles(props);

  // Store references which are populated when the component is mounted.  SSR does not have window until
  // after the component has mounted.
  let url = useRef();
  let querystring = useRef();

  // Prompt user to login and retrieve account-specific details
  const [showLogin, setShowLogin] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  // Show payment modal
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  // Current organization vitals.
  const [organizationHash, setOrganizationHash] = useState(null);
  const [organizationId, setOrganizationId] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);

  // Current counts sms recipients.  If the querystring includes values, initialize with them.
  const [smsRecipients, setSmsRecipients] = useState([SMS_RECIPIENT_DEFAULT]);

  // Track input field
  let smsRecipientsEl = useRef(null);
  let smsRecipientsPlaceholderEl = useRef(null);
  const [editingSmsRecipients, setEditingSmsRecipients] = useState(false);

  // Messages per recipient
  const [averageSmsPerRecipient, setAverageSmsPerRecipient] = useState(SMS_PER_RECIPIENT_DEFAULT)
  
  // Track input field
  let averageSmsPerRecipientEl = useRef(null);
  let averageSmsPerRecipientPlaceholderEl = useRef(null);
  const [editingAverageSmsPerRecipient, setEditingAverageSmsPerRecipient] = useState(false);

  // Annual messages sent
  const [estimatedMessages, setEstimatedMessages] = useState(SMS_RECIPIENT_DEFAULT * SMS_PER_RECIPIENT_DEFAULT);
  
  // Track input field
  let estimatedMessagesEl = useRef(null);
  let estimatedMessagesPlaceholderEl = useRef(null);
  const [editingEstimatedMessages, setEditingEstimatedMessages] = useState(false);

  // Total cost for sender
  const [totalCost, setTotalCost] = useState(null)


  // Set initial values based on querystring parameters if they exist
  useEffect(() => {
    // Grab the original link href, parse the querystring.
    url.current = new URL(window.location.href).search;
    querystring.current = qs.parse(url.current, { ignoreQueryPrefix: true });

    // Prompt the user to log in and retrieve their account-specific details.
    if (isObjectEmpty(querystring.current)) {
      setShowLogin(true)
    }

    if (querystring && querystring.current.a) {
      setOrganizationId(querystring.current.a);
    }

    if (querystring && querystring.current.h) {
      setOrganizationHash(querystring.current.h)
    }

    if (querystring && querystring.current.n) {
      setOrganizationName(querystring.current.n);
    }

    if (querystring && querystring.current.sms) {
      setSmsRecipients([querystring.current.sms]);
    }

    if (querystring && querystring.current.averageSmsPerRecipient) {
      setAverageSmsPerRecipient(querystring.current.averageSmsPerRecipient);
    }

    // If an average sms per recipient value and an sms recipient value are passed, set the estimated messages.
    if (querystring && querystring.current.averageSmsPerRecipient && querystring.current.sms) {
      setEstimatedMessages(parseInt(querystring.current.sms * querystring.current.averageSmsPerRecipient));
    }

    // If an average sms per recipient value and an sms recipient value are passed, set the estimated messages.
    if (querystring && !querystring.current.averageSmsPerRecipient && querystring.current.sms) {
      setEstimatedMessages(parseInt(querystring.current.sms * SMS_PER_RECIPIENT_DEFAULT));
    }
    
  }, [querystring, url])


  // Calculate estimated messages, ensure the total cost satisfies the minimum.
  useEffect(() => {
    const newTotalCost = Math.max(STRIPE_PURCHASE_MIN, averageSmsPerRecipient * smsRecipients[0] * UNIT_COST_USD);
    setTotalCost(newTotalCost);
  }, [averageSmsPerRecipient, smsRecipients])


  // Set focus on SMS recipient count
  useEffect(() => {
    if (editingSmsRecipients) {
      smsRecipientsEl.current.focus();
    }
  }, [editingSmsRecipients])

  
  // Set focus on average per recipient
  useEffect(() => {
    if (editingAverageSmsPerRecipient) {
      averageSmsPerRecipientEl.current.focus();
    }
  }, [editingAverageSmsPerRecipient])


  // Set focus on estimated messages
  useEffect(() => {
    if (editingEstimatedMessages) {
      estimatedMessagesEl.current.focus();
    }
  }, [editingEstimatedMessages])


  const renderTrack = ({ props, children }) => (
    <div
      onMouseDown={props.onMouseDown}
      onTouchStart={props.onTouchStart}
      style={{
        ...props.style,
        ...rangeTrack
      }}
      role="button"
      tabIndex={0}
    >
      <div
        ref={props.ref}
        style={{
          ...rangeTrackInner,
          background: getTrackBackground({
            values: smsRecipients,
            colors: [COLORS.BLUE_02, COLORS.BLUE_MUTED_03],
            min: SMS_RECIPIENT_MINIMUM,
            max: SMS_RECIPIENT_MAXIMUM
          })
        }}
      >
        {children}
      </div>
    </div>
  )

  const renderThumb = ({ props, isDragged }) => (
    <div
      {...props}
      style={{
        ...props.style,
        ...rangeThumb
      }}
    >
      <div
        style={{
          ...rangeThumbInner,
          backgroundColor: isDragged ? COLORS.PURPLE_01 : COLORS.LIGHT_02
        }}
      />
    </div>
  )

  // Manually drive the changing of values as we don't want everything to run at once in an effect.
  // Ensure the minimum values are met below when modifying our inputs.
  const onBlurSmsRecipients = smsRecipients => {
    setEditingSmsRecipients(false)
  }

  // Update the number of SMS recipients along with the total estimated number of messages.
  const onChangeSmsRecipients = smsRecipients => {
    setSmsRecipients([smsRecipients]);
    setEstimatedMessages(smsRecipients * averageSmsPerRecipient);
    trackCustomEvent({
      category: ANALYTICS.CATEGORY.PRICING_CALCULATOR,
      action: ANALYTICS.ACTION.PRICING_CALCULATOR_RECIPIENT_INPUT,
      value: smsRecipients
    })
  }

  const onBlurAverageSmsPerRecipient = averageSmsPerRecipient => {
    setEditingAverageSmsPerRecipient(false)
  }

  // Update the number of SMS sent per recipient  along with the estimated number of messages.
  const onChangeAverageSmsPerRecipient = averageSmsPerRecipient => {
      setAverageSmsPerRecipient(averageSmsPerRecipient);
      setEstimatedMessages(averageSmsPerRecipient * smsRecipients);
      trackCustomEvent({
        category: ANALYTICS.CATEGORY.PRICING_CALCULATOR,
        action: ANALYTICS.ACTION.PRICING_CALCULATOR_SMS_PER_RECIPIENT_INPUT,
        value: averageSmsPerRecipient
      })
  }

  const onBlurEstimatedMessages = estimatedMessages => {
    setEditingEstimatedMessages(false)
    if(estimatedMessages < STRIPE_MESSAGE_QTY_MIN) {
      setEstimatedMessages(STRIPE_MESSAGE_QTY_MIN)
      setAverageSmsPerRecipient(Math.round(STRIPE_MESSAGE_QTY_MIN / smsRecipients[0]))
    }
  }

  // Update the estimated number of messages and the SMS per recipient average.
  const onChangeEstimatedMessages = estimatedMessages => {
    setEstimatedMessages(parseInt(estimatedMessages))
    setAverageSmsPerRecipient(Math.round(estimatedMessages / smsRecipients[0]))
    trackCustomEvent({
      category: ANALYTICS.CATEGORY.PRICING_CALCULATOR,
      action: ANALYTICS.ACTION.PRICING_CALCULATOR_TOTAL_SMS_INPUT,
      value: estimatedMessages
    })
  }


  /**
   * Plan 1 - Sender Pays
   */
  const renderPlanOne = () => {
    const getTickPosition = value => Math.min(((value - SMS_RECIPIENT_MINIMUM) / (SMS_RECIPIENT_MAXIMUM - SMS_RECIPIENT_MINIMUM)), 1) * 100

    return (
    <div className={classes.planCalculator}>
      <div className={classes.boxTitle}>{organizationName ? `Calculate ${organizationName}'s Cost`: "Use the Calculator to Estimate Your Needs"}</div>
      <div className={classes.box}>
        <div className={classes.boxHeader}>
          {showLogin ? 
          <div className={classes.alternate}>For your account details —&nbsp;
            <span
              className={classes.alternateLink}
              onClick={() => setShowLoginModal(true)}
              onKeyDown={event => {
                if (event.keyCode === KEY_CODES.ENTER) {
                  setShowLoginModal(true)
                }}
              }
              role="link"
              tabIndex={0}
            >
              Log in here
            </span>
          </div> : 
          <div className={classes.alternate}>
            <img src={Notification} alt="Notification icon" className={classes.notificationIcon} />There is a {new Intl.NumberFormat(LOCALE, { style: 'currency', currency: CURRENCY }).format(STRIPE_PURCHASE_MIN)} minimum purchase of {STRIPE_MESSAGE_QTY_MIN} messages.
          </div>
          }
        </div> 
        <div className={classes.qualitySliderAndTally}>
          <div className={classes.quantitySlider}>
            <div className={classes.quantityTitle}>How many recipients do you have?</div>
            <div className={classes.quantitySliderInput}>
              <Range
                step={SMS_RECIPIENT_STEP}
                min={SMS_RECIPIENT_MINIMUM}
                max={SMS_RECIPIENT_MAXIMUM}
                values={smsRecipients}
                onChange={values => onChangeSmsRecipients(values)}
                renderTrack={renderTrack}
                renderThumb={renderThumb}
              />
              <div className={classes.rangeTicks}>
                {SMS_RECIPIENT_TICKS.map((value, idx) => 
                  <div key={idx} className={classes.rangeTickMark} style={{ left: getTickPosition(value) + '%' }}>
                    <div className={classes.rangeTickMarkValue} style={{ left: getTickPosition(value) + '%' }}>
                      {idx === SMS_RECIPIENT_TICKS.length-1 ? numberWithCommas(SMS_RECIPIENT_TICKS[idx]) + '+' : numberWithCommas(SMS_RECIPIENT_TICKS[idx])}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* Uncomment when we want to add the tally */}
          {/* <div className={classes.tally}>
            <div className={classes.tallyTitle}>You need</div>
            <div className={classes.tallyValue}>{numberWithCommas(estimatedMessages)}</div>
            <div className={classes.tallySubtitle}>messages</div>
          </div> */}
        </div>
        <div className={classes.boxOverview}>

          <div className={classes.overviewItem} data-tip={editingSmsRecipients ? null : "Estimated number of SMS recipients"}>
            <div className={classes.overviewItemLabel}>Recipient{smsRecipients[0] > 1 ? 's' : ''}</div>
            <div className={classes.overviewItemValue}>
              {editingSmsRecipients ? 
              <input
                ref={smsRecipientsEl}
                type="number"
                value={smsRecipients[0]}
                className={classes.overviewItemInput}
                onChange={event => onChangeSmsRecipients(event.currentTarget.value)}
                onBlur={event => onBlurSmsRecipients(event.currentTarget.value)}
                tabIndex={0}
              /> : 
              <div
                ref={smsRecipientsPlaceholderEl}
                className={classes.overviewItemPlaceholder} 
                onClick={() => setEditingSmsRecipients(true)}
                onKeyDown={event => {
                  if (event.keyCode === KEY_CODES.ENTER) {
                    setEditingSmsRecipients(true)
                  }}
                }
                onFocus={() => smsRecipientsPlaceholderEl.current.click()} 
                tabIndex={0}
                role="textbox"
              >
                  {numberWithCommas(smsRecipients[0])}
                </div>
              }
            </div>
          </div>

          <div className={classes.overviewItem} data-tip={editingAverageSmsPerRecipient ? null : "Estimated messages you will send to each recipient"}>
            <div className={classes.overviewItemLabel}>SMS per Recipient</div>
            <div className={classes.overviewItemValue}>
              {editingAverageSmsPerRecipient ? 
              <input
                ref={averageSmsPerRecipientEl}
                type="number"
                value={averageSmsPerRecipient}
                className={classes.overviewItemInput}
                onChange={event => onChangeAverageSmsPerRecipient(event.currentTarget.value)}
                onBlur={event => onBlurAverageSmsPerRecipient(event.currentTarget.value)}
                tabIndex={0}
              /> : 
              <div
                ref={averageSmsPerRecipientPlaceholderEl}
                className={classes.overviewItemPlaceholder}
                onClick={() => setEditingAverageSmsPerRecipient(true)}
                onKeyDown={event => {
                  if (event.keyCode === KEY_CODES.ENTER) {
                    setEditingAverageSmsPerRecipient(true)
                  }}
                }
                onFocus={() => averageSmsPerRecipientPlaceholderEl.current.click()} 
                role="textbox"
                tabIndex={0}
              >
                {numberWithCommas(averageSmsPerRecipient)}
              </div>
              }
            </div>
          </div>

          <div className={classes.overviewItem} data-tip={editingEstimatedMessages ? null : `Estimated Total = Recipients × SMS per recipient. You must purchase at least ${STRIPE_MESSAGE_QTY_MIN} messages to satisfy the ${new Intl.NumberFormat(LOCALE, { style: 'currency', currency: CURRENCY }).format(STRIPE_PURCHASE_MIN).replace(/\D00$/, '')} minimum purchase at checkout.`}>
            <div className={classes.overviewItemLabel}>Total SMS Sent</div>
            <div className={classes.overviewItemValue}>
              {editingEstimatedMessages ? 
              <input
                ref={estimatedMessagesEl}
                type="number"
                value={estimatedMessages}
                className={classes.overviewItemInput}
                onBlur={event => onBlurEstimatedMessages(event.currentTarget.value)}
                onChange={event => onChangeEstimatedMessages(event.currentTarget.value)}
                tabIndex={0}
              /> :
              <div
                ref={estimatedMessagesPlaceholderEl}
                className={classes.overviewItemPlaceholder}
                onClick={() => setEditingEstimatedMessages(true)}
                onKeyDown={event => {
                  if (event.keyCode === KEY_CODES.ENTER) {
                    setEditingEstimatedMessages(true)
                  }}
                }
                onFocus={() => estimatedMessagesPlaceholderEl.current.click()} 
                tabIndex={0}
                role="textbox"
              >
                {numberWithCommas(estimatedMessages)}
              </div>
              }
            </div>
          </div>

          <div className={classes.overviewItem} data-tip={`Estimated annual cost to sender — There is a ${new Intl.NumberFormat(LOCALE, { style: 'currency', currency: CURRENCY }).format(STRIPE_PURCHASE_MIN).replace(/\D00$/, '')} minimum purchase at checkout.`}>
            <div className={classes.overviewItemLabel}>Sender Cost</div>
            <div className={classes.overviewItemValue}>
            <div className={classes.overviewItemDisplayOnly}>
              {new Intl.NumberFormat(LOCALE, { style: 'currency', currency: CURRENCY }).format(totalCost).replace(/\D00$/, '')}/yr
              </div>
            </div>
          </div>

          <div className={classes.overviewItem} data-tip={`If you can add this cost to a registration fee, you would need to add ${new Intl.NumberFormat(LOCALE, { style: 'currency', currency: CURRENCY }).format(averageSmsPerRecipient * UNIT_COST_USD).replace(/\D00$/, '')} per recipient each year`}>
            <div className={classes.overviewItemLabel}>Cost Per Recipient</div>
            <div className={classes.overviewItemValue}>
              <div className={classes.overviewItemDisplayOnly}>
                {new Intl.NumberFormat(LOCALE, { style: 'currency', currency: CURRENCY }).format(averageSmsPerRecipient * UNIT_COST_USD).replace(/\D00$/, '')}/yr
              </div>
            </div>
          </div>
        </div>
      </div>

      <PlanLoginOrCheckout
        className={classes.planLoginOrCheckout}
        showLogin={showLogin}
        onCheckout={event => setShowPaymentModal(true)}
        onLogin={event => setShowLoginModal(true)}
        onCreateOrganization={event => navigate("/create-organization")}
      />

      <AdminLoginModal
        isShowing={showLoginModal}
        hide={() => setShowLoginModal(false)}
      />

      <PaymentModal
        isShowing={showPaymentModal}
        hide={() => setShowPaymentModal(false)}
        selectedPlan={props.selectedPlan}
        organizationHash={organizationHash}
        organizationId={organizationId}
        estimatedMessages={estimatedMessages}
      />

      <ReactTooltip className={classes.tooltip} effect="solid" />
    </div>
  )}


  /**
   * Plan 2- Recipient Pays
   */
  const renderPlanTwo = () => (
    <div className={classes.planCalculator}>
      <p>Let the recipient choose and your life as a coach, manager or leader does not change – you just send out messages like you always have.</p>
      <p>Each recipient can get email for free or they can upgrade to text.  Text is {new Intl.NumberFormat(LOCALE, { style: 'currency', currency: CURRENCY }).format(ANNUAL_COST_USD)} per year billed annually. This works out to 2 cents per day and gets them unlimited messages from unlimited RainedOut accounts.</p>
      <p>When you confirm that you are choosing Recipient Choice, then we will contact everyone receiving texts from you and notify them of the need to make a choice.</p>
      <p style={{ marginBottom: 60 }}>Learn more below.</p>

      { props.disableSelectPlan ? null : 
      <PlanLoginOrCheckout
        className={classes.planLoginOrCheckout}
        showLogin={showLogin}
        checkoutText="Continue"
        onCheckout={event => setShowPaymentModal(true)}
        onLogin={event => setShowLoginModal(true)}
        onCreateOrganization={event => navigate("/create-organization")}
      /> }

      <AdminLoginModal
        isShowing={showLoginModal}
        hide={() => setShowLoginModal(false)}
      />

      <PaymentModal
        isShowing={showPaymentModal}
        hide={() => setShowPaymentModal(false)}
        selectedPlan={props.selectedPlan}
        organizationHash={organizationHash}
        organizationId={organizationId}
        estimatedMessages={estimatedMessages}
      />

    </div>
  )

  return (props.selectedPlan === STRIPE_PLAN_SENDER_PAYS ? renderPlanOne() : renderPlanTwo())
}

PlanCalculator.propTypes = {
  disableSelectPlan: PropTypes.bool,
  selectedPlan: PropTypes.oneOf([STRIPE_PLAN_RECIPIENT_PAYS, STRIPE_PLAN_SENDER_PAYS]).isRequired,
}

PlanCalculator.defaultProps = {
  disableSelectPlan: false
}

export default PlanCalculator
