import {createUseStyles} from 'react-jss'

import {
  BREAKPOINTS
} from '~styles/vars.styles'

import {
  tooltip,
  uppercaseLabel
} from '~styles/mixins.styles'


export default createUseStyles({
  planLoginOrCheckout: {

  },

  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    flexDirection: 'column',
    [`@media (min-width: ${BREAKPOINTS.PLAN_LOGIN_OR_CHECKOUT_BUTTON_GROUP_BIG}px)`]: {
      justifyContent: 'flex-start',
      flexDirection: 'row',
      flexWrap: 'nowrap'
    }
  },

  buttonSmall: {
    width: '100%',
    [`@media (min-width: ${BREAKPOINTS.PLAN_LOGIN_OR_CHECKOUT_BUTTON_GROUP_BIG}px)`]: {
      flexBasis: 225,
      maxWidth: 250
    }
  },

  buttonBetwixt: {
    ...uppercaseLabel,
    fontSize: 16,
    margin: 20
  },

  tooltip: {
    ...tooltip
  }
})