import { createUseStyles } from 'react-jss'

export default createUseStyles({
  linkWatchVideo: {
    display: 'block',
    margin: [30, 0],
  },
  planBullets: {
    marginBottom: 60
  }
})