import React from "react"
import classNames from 'classnames';
import PropTypes from 'prop-types';

import PlanBullet from '~components/plan-bullet/plan-bullet.component';

import {
  STRIPE_PLAN_RECIPIENT_PAYS,
  STRIPE_PLAN_SENDER_PAYS
} from '~common/constants'

import useStyles from './plan-bullets.styles';

const PLAN_BULLET_ITEM_TITLE_SENDER_PAYS = 'Sender “Pay As You Go” Plan';
const PLAN_BULLET_ITEM_TITLE_RECIPIENT_CHOICE = 'Recipient “Choice” Plan';

const PLAN_BULLET_CONTENT_SENDER_PAYS = [
  {
    title: 'Who Typically Chooses This Plan?',
    items: [{
      title: PLAN_BULLET_ITEM_TITLE_SENDER_PAYS,
      body: () => (
        <ul>
          <li>Rec Councils and Club teams for field changes, game cancellations</li>
          <li>Libraries and Schools for snow day announcements</li>
        </ul>
        )
    }]
  }, {
    title: 'Why Would I Pick This Plan?',
    items: [{
      title: PLAN_BULLET_ITEM_TITLE_SENDER_PAYS,
      body: () => (
        <ul>
          <li>You send messages every once in a while</li>
          <li>You can spread the payment out by adding a small fee to your registration fees.  In most cases the additional fee is $1 to $2.</li>
        </ul>
      )
    }]
  }, {
    title: 'How Does This Plan Work?',
    items: [{
      title: PLAN_BULLET_ITEM_TITLE_SENDER_PAYS,
      body: () => (
        <ol>
          <li>Go to the top of this page and select Sender Plan.</li>
          <li>Scroll down and log in using your RainedOut username and password.</li>
          <li>When you log in, we will update the calculator with your actual SMS recipient number.</li>
          <li>Use the calculator to determine your text messaging needs.</li>
          <li>Check out to pre-pay for a block of text messages.  Messages do not expire.  Emails are free.</li>
          <li>Start sending messages just like you always have!</li>
        </ol>
      )
    }]
  }
];

const PLAN_BULLET_CONTENT_RECIPIENT_CHOICE = [
  {
    title: 'Who Typically Chooses This Plan?',
    items: [{
      title: PLAN_BULLET_ITEM_TITLE_RECIPIENT_CHOICE,
      body: () => (
        <ul>
          <li>News and traffic groups for multiple updates per day</li>
        </ul>
      )
    }]
  }, {
    title: 'Why Would I Pick This Plan?',
    items: [{
      title: PLAN_BULLET_ITEM_TITLE_RECIPIENT_CHOICE,
      body: () => (
        <ul>
          <li>You send a high volume of messages and need an unlimited plan</li>
          <li>You have many recipients that may use the service at different times</li>
        </ul>
      )
    }]
  }, {
    title: 'How Does This Plan Work?',
    items: [{
      title: PLAN_BULLET_ITEM_TITLE_RECIPIENT_CHOICE,
      body: () => (
        <ol>
          <li>Go to the top of this page and select Recipient Choice by clicking on the box.</li>
          <li>Scroll down and log in using your RainedOut username and password.</li>
          <li>Click Select and then Click Confirm. We added a second step to make sure you are ready.</li>
          <li>When you confirm that you are choosing Recipient Choice, then we will contact everyone receiving texts from you and notify them of the need to make a choice.</li>
          <li>If they want to continue receiving text messages, they can pay right from their phones.</li>
          <li>If they want receive free emails, they can go RainedOut.com and click Support. Here they can unsubscribe from text and subscribe for email.</li>
        </ol>
      )
    }]
  }
]

const PlanBullets = props => {
  const classes = useStyles(props);
  return (
    <div
      className={classNames(
        [classes.planBullets],
        {
          [props.className]: !!props.className
        }
      )}
    >
      {props.selectedPlan === STRIPE_PLAN_SENDER_PAYS ? 
      PLAN_BULLET_CONTENT_SENDER_PAYS
        .map((item, idx) => (
          <PlanBullet key={idx} title={item.title}>
            {item.items}
          </PlanBullet>
        )) : 
        PLAN_BULLET_CONTENT_RECIPIENT_CHOICE
        .map((item, idx) => (
          <PlanBullet key={idx} title={item.title}>
            {item.items}
          </PlanBullet>
        ))
      }
    </div>
  );
}

PlanBullets.propTypes = {
  selectedPlan: PropTypes.oneOf([STRIPE_PLAN_RECIPIENT_PAYS, STRIPE_PLAN_SENDER_PAYS]),
}

PlanBullets.defaultProps = {
  selectedPlan: null
}

export default PlanBullets
