import React, { useEffect, useRef, useState } from "react"

import AdminLoginForm from '~components/admin-login-form/admin-login-form.component'
import Modal from '~components/modal/modal.component'
import SearchOrganizations from '~components/search-organizations/search-organizations.component';

const AdminLoginModal = props => {
  const [organizationId, setOrganizationId] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);

  let windowLocationOrigin = useRef();

  useEffect(() => {
    windowLocationOrigin.current = window.location.origin;
  }, [])

  return (
      <Modal
        title={organizationName || "Admin Log In"}
        isShowing={props.isShowing}
        hide={props.hide}
      >
        {organizationId ? 
        <AdminLoginForm redirectUrl={`${windowLocationOrigin.current}/pick-a-plan`} organizationHash={organizationId} /> : 
        <SearchOrganizations
          onSelectedOrganizationChange={
            (organizationId, organizationName) => {
              setOrganizationId(organizationId)
              setOrganizationName(organizationName)
              }
          } 
        />}
      </Modal>
  )
}

export default AdminLoginModal
